<template>
  <div class="page-box">
    <ol class="citation-list">
      <li v-for="(item, index) in citationList" :key="index">{{item}}</li>
    </ol>
  </div>
</template>
<script>
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  data () {
    return {
      groupId: '',
      citationList: []
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.id) {
      next(vm => {
        vm.groupId = to.query.id
        vm.loadCitationList()
      })
    } else {
      next()
    }
  },
  methods: {
    loadCitationList () {
      this.$http.get(`${this.httpRoot}/group/citation/list`, authenticate({params: {id: this.groupId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.citationList = res.citationList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>
<style lang="less">
.citation-list {
  padding-top: 15px;
  &> li {
    margin: 10px auto;
    font-size: 14px;
  }
}
</style>
